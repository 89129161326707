import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import NestedContentRow from "../components/nestedContentRow";
import ALChart from "../components/ALMobileChart";

import ALTechVideo from "../images/ALMobileVideo.mp4";
import ALTechPoster from "../images/ALPosterGeneral.jpg";
import NumberLine from "../components/NumberLine";
import FAQ from "../components/FAQ";
import smoothScrollTo from "../components/smoothScroll";

const ProductionPage = () => {
    const imgData = useStaticQuery(graphql`
        query {
            fullClientDesktop: file(relativePath: { eq: "AllDevicesProdBubble.png" }) {
                childImageSharp {
                    fluid(maxWidth: 550) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            fullClientMobile: file(relativePath: { eq: "AllDevicesProd.png" }) {
                childImageSharp {
                    fluid(maxWidth: 376) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            arrowDownWhiteIcon: file(relativePath: { eq: "ArrowDownWhite.png" }) {
                childImageSharp {
                    fixed(width: 15) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            ipadProd: file(relativePath: { eq: "ipadProd.png" }) {
                childImageSharp {
                    fluid(maxWidth: 350) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            ipadProdEntry: file(relativePath: { eq: "ipadProdEntry.png" }) {
                childImageSharp {
                    fluid(maxWidth: 350) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            ateMobile: file(relativePath: { eq: "ATEMobile.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    const mediaQuerySources = [
        {
            ...imgData.fullClientMobile.childImageSharp.fluid,
            media: `(max-width: 1366px)`
        },
        {
            ...imgData.fullClientDesktop.childImageSharp.fluid,
            media: `(min-width: 1367px)`,
        }
    ];
    const FAQData = [
        {
            question: "Why do I need ALMobile Production Tracking?",
            answer: ["ALMobile Production Tracking answers the need to improve profits and performance with projects. This productivity tool empowers everyone across your organization to make the right decisions at the right time. ALMobile measures and tracks production on projects and job sites located all over the world. ALMobile Production Tracking is engineered perfectly to integrate with Time Tracking to optimize workforce productivity and project completions on every project."]
        },
        {
            question: "What is the difference between Lead and Lag measures?",
            answer: ["The Lag measure is what helps to drive down costs and increase profit. The Lead measure is what operations can use to measure before they know the Lag measure. The Lead measure has direct influence on the Lag measure, and is directly in the control of the worker. In construction, the Lead measures are hours expended and work completed. A Lag measure is after the fact, by the time you see the cost information, the performance that drove the measurement has already passed. ALMobile Production Tracking allows the Lead measure to be easily captured in the field as the project progresses in real time. ALMobile provides instant feedback to the field user (i.e. Foremen and/or Supervisor) that affects change, at a point that the change can affect the Lag measure."]
        },
        {
            question : "Does the productivity information need to be entered daily?",
            answer: ["No, the production measurement can occur at any time based on your needs. Foremen can enter information daily and receive instant budget against actual reporting. Another common approach is to have Foremen maintain crew time and Supervisors perform the Production Tracking periodically over the life of the project. The goal is to drive the success of the Lag measure. You want Lead measure tracking that has predictive outcome and that is influenced by the individual recording the measurement."]
        },
        {
            question : "Does your solution track and report production costs?",
            answer: [
                "We allow the user to measure productivity in units or percent complete for the tasks they have control over. Displaying the accumulation of cost is a Lag measure that should be quantified by administrative personnel (i.e. accounting). Therefore, we believe making project changes based on the Lag measure are less effective. With field time captured by ALMobile Time Tracking, half of the Production Tracking workflow is complete. The integrated displays available in ALMobile Production Tracking are founded on accurate and timely information entered by the field."
            ]
        },
        {
            question : "Why would I want to enter Percent Complete on a task versus the units of work in place?",
            answer: ["As a productivity tracking solution, ALMobile can be deployed with either percent complete or units in place. This allows the user to enter units of work produced per a given amount of man hours, and calculate the overall percent complete for that specific task. There are environments where the task being measured is not so easy to count, the user has the ability to enter the total percent complete, and Production Tracking will calculate the number of units of work placed based on previous associated time entries."]
        },
        {
            question : "Where does the unit of measurement come from?",
            answer: ["Through our bi-directional integration with your ERP, the unit of measurement is captured and displayed by project task."]
        },
        {
            question : "What is Subcontractor Production Tracking?",
            answer: ["Subcontractor Production Tracking allows a company to track the productivity rate of specialized work contracted to sub-trades. Similar to self-performed work, this feature tracks sub-trade activity and progress based on their schedule of values. Designed for the contractor to measure progress and approve payment requests and ensure the project schedule is not impeded by a sub-trade."]
        },
        {
            question : "How is the Daily Diary leveraged?",
            answer: ["Daily Diary was built to capture the field's project notes and enable companies to search and report on activities that occurred over the life of the job. The Daily Diary collects daily information about weather, incidents, issues, progress, and more. It can be leveraged to resolve conflicts and support decisions or claims from specific dates. It is a powerful tool that captures images, videos and audio about the overall environment of the project."]
        },
        {
            question : "Do you have a Job Report by project?",
            answer: ["Yes, our Daily Job Report is a comprehensive report that measures the state of the project, including weather conditions, labor activity, equipment usage, productivity, site visitors, job notes, and more. It has been designed to inform management daily on the status of the project."]
        }
    ];
    const numberRows = {
        general: [
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 1 }}
                        iconData={{
                            iconType: "fluid",
                            icon: imgData.ipadProd.childImageSharp.fluid,
                            iconAlt: "ALGraphicalProduction"
                        }}
                        textData={{
                            level1: "Track All Job Activity on One Screen",
                            level2: "Create, track, and update action items all in one place. Easily track punch list items, weather and other project delays, and subcontractor counts and hours in a system designed for your field team and project managers"
                        }}
                        link={<div className={"level3"}>
                            <Link className={"link btn"} to={`/events/`} state={{component: "webinars"}}>Learn About Our Production</Link>
                        </div>}
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 2 }}
                        iconData={{
                            icon: imgData.ipadProdEntry.childImageSharp.fluid,
                            iconAlt: "ALMobile Production Entry",
                            iconType: "fluid"
                        }}
                        textData={{
                            level1: "Use Predictive Analytics to Improve Project Completions",
                            level2: "ALMobile's use of predictive analytics allows you to enter completed production quantities and man hours per job and/or per task with just a few taps of your finger. Compare actual units and man hours vs. budget in real-time, and calculate employees, equipment and activities required to complete tasks with ease"
                        }}
                        link={<div className={"level3"}>
                            <Link className={"link btn"} to={`/events/`} state={{component: "webinars"}}>See Our Predictive Analytics</Link>
                        </div>}
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 3 }}
                        iconData={{
                            iconType: "fluid",
                            icon: imgData.ateMobile.childImageSharp.fluid,
                            iconAlt: "ALMobile Menu",
                        }}
                        textData={{
                            level1: "Compare Actual Progress to Projected Budgets Anytime, Anywhere",
                            level2: "Never be over budget again. You have the power to control budget overages in a timely fashion, and know if you are on track and on budget in real-time 24/7. Visualize how many hours have been actually used against budgeted hours, compare actual units and hours to estimated quantities and time for each cost code from your mobile device or laptop"
                        }}
                        link={<div className={"level3"}>
                            <Link className={"link btn"} to={`/events/`} state={{component: "webinars"}}>Register for a Webinar to See How</Link>
                        </div>}
                    />
            },
            {
                data:
                    <NestedContentRow
                        customProps={{ "data-id": 4 }}
                        textData={{
                            level1: "Beat the Competition",
                            level2: "ALMobile's Production Tracking provides predictive analytics, intelligental reporting, real-time productivity, and innovative technology"
                        }}
                    />
            }
        ],
        video:
            <video
                controls
                poster={ALTechPoster}
            >
                <source src={ALTechVideo}/>
            </video>
    };
    const [aboutActive, toggleActive] = useState(false);
    const startScroll = _ => {
        const row1 = document.querySelector(".numberRow[data-id='1']");
        smoothScrollTo(row1.offsetTop, 300);
    };
    const setAboutActive = (state) => toggleActive(state);
    return (
        <Layout pageID={"ALMobileProductionTracking"}>
            <SEO
                title="ALMobile Production Tracking"
                description={"ALMobile Production Tracking is an ERP-integrated, productivity tracking tool. ALMobile measures and tracks production data on projects and job sites located all over the world."}
            />
            <div className={"contentRow productOverview"}>
                <div className={"subContentRow"}>
                    <div className={"textContainer"}>
                        <div className={"ProductOverviewMainSubHeader"}>PRODUCT OVERVIEW</div>
                        <div className={"level1 text"}>
                            ALMobile Production Tracking
                        </div>
                        <div className={"level2 text"}>
                            ALMobile's Production Tracking allows you to easily keep an eye on all active and current
                            projects and their data. Visualize job progress, receive real-time updates about projects,
                            and easily filter budget against actual data you need, when you need it on one screen.
                        </div>
                    </div>
                    <div className={"startOverviewContainer"}>
                        <div
                            className={"startOverviewBtnContainer"}
                            onClick={startScroll}
                            onKeyUp={startScroll}
                        >
                            <Img
                                className={"startOverviewBtn"}
                                fixed={imgData.arrowDownWhiteIcon.childImageSharp.fixed}
                                alt={"Start Btn"}
                                style={{ display: "block" }}
                            />
                        </div>
                        <div
                            className={"startOverviewLabel"}
                            onClick={startScroll}
                            onKeyUp={startScroll}
                        >
                            LEARN MORE
                        </div>
                    </div>
                    <Img
                        className={"imgContainer"}
                        style={{ display: "block" }}
                        fluid={mediaQuerySources}
                        imgAlt={"ALMobile Desktop"}
                    />
                </div>
            </div>
            <NumberLine rows={numberRows}/>
            <div id={"ALChart"} className={`contentRow ${(aboutActive) ? "active" : ""}`}>
                <ALChart parent="ALChart" id={"timePageChart"} clickCallback={setAboutActive}/>
            </div>
            <FAQ QAData={FAQData} />
        </Layout>
    )
};

export default ProductionPage;
